<template>
  <TransitionRoot as="template" :show="open">
    <Dialog as="div" class="relative z-50 select-none">
      <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0" enter-to="opacity-100" leave="ease-in duration-200" leave-from="opacity-100" leave-to="opacity-0">
        <div class="fixed inset-0 bg-gray-500 bg-opacity-75 transition-opacity background" />
      </TransitionChild>

      <div class="fixed inset-0 z-50 w-screen overflow-y-auto">
        <div class="flex min-h-full items-center justify-center p-4 text-center sm:items-center sm:p-0">
          <TransitionChild as="template" enter="ease-out duration-300" enter-from="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95" enter-to="opacity-100 translate-y-0 sm:scale-100" leave="ease-in duration-200" leave-from="opacity-100 translate-y-0 sm:scale-100" leave-to="opacity-0 translate-y-4 sm:translate-y-0 sm:scale-95">
            <DialogPanel class="relative transform overflow-hidden rounded-lg bg-white px-4 pb-4 pt-5 text-left shadow-xl transition-all sm:my-8 sm:w-full sm:max-w-fit sm:p-6">
              <div>
                <div class="mx-auto flex h-14 w-14 items-center justify-center rounded-full bg-blue-50">
                  <img src="../assets/promotion.svg" class="h-8 w-8" aria-hidden="true" />
                </div>
                <div class="mt-2 text-center sm:mt-2">
                  <DialogTitle as="h3" class="text-2xl font-semibold leading-6 text-gray-700">Gratulacje!</DialogTitle>
                  <div class="text-sm sm:text-base mt-2 flex flex-col text-gray-500 md:px-6">
                    <p class="">Awansowałeś na stanowisko: <span class="text-gray-700 font-semibold truncate underline">{{$store.state.userData.role.name}}!</span></p>
                    <!-- <div class="break-words">Aktualnie za umowę <span class="font-semibold text-gray-700">STANDARD</span> otrzymasz:</div> -->
                    <div class="justify-start flex gap-1"><p class="text-left">- Prowizja za kW wynosi: <span class="text-[#3eba83] font-bold">{{$store.state.userData.role.promotionInformations.kw.toFixed(2)}} zł</span></p></div>
                    <div class="justify-start flex gap-1"><p class="text-left">- Prowizja za pompę ciepła wynosi: <span class="text-[#3eba83] font-bold">{{$store.state.userData.role.promotionInformations.heatPump.toFixed(2)}} zł</span></p></div>
                    <p class="text-xs sm:text-sm mt-1">Aby poznać więcej szegółów przejdź do kariery.</p>
                  </div>
                </div>
              </div>
            <div class="mt-1 flex items-center justify-end gap-2 pt-4 w-full">
                <!-- <div :class="['bg-[#3eba83] cursor-pointer hover:bg-[#3b9c70] transition-all duration-300 focus:ring-[#3eba83] w-full flex items-center justify-center  rounded-md border border-transparent shadow-sm px-4 py-2 font-medium text-white focus:outline-none focus:ring-2 focus:ring-offset-2 md:ml-3 text-xs' ]" @click="modalEvent(true)" > Przejdź do kariery </div>
                <div class="w-full sm:w-auto mt-1 inline-flex cursor-pointer justify-center rounded-md border border-gray-300 shadow-sm px-4 py-2 bg-white font-medium text-gray-700 hover:bg-gray-50 transition-all duration-300 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-[#3eba83] md:mt-0 text-xs" ref="cancelButtonRef" @click="modalEvent(false)">Wyjdź</div> -->
                <button @click="modalEvent(false)" type="button" :class="[false ? 'bg-gray-200 cursor-not-allowed text-gray-500' : 'bg-red-100 text-red-500 hover:bg-red-200 focus:outline-none focus:ring-2 focus:ring-red-500 focus:ring-offset-2']" class="justify-center whitespace-nowrap transition-all duration-300 rounded-md  text-xs font-semibold  shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 inline-flex items-center px-2.5 py-1.5  select-none">
                  <ReplyIcon class="-ml-0.5 h-5 w-5 mr-1" aria-hidden="true" />
                  <span>Wyjdź</span>
                </button>
                <button @click="modalEvent(true)" v-auto-animate type="button" :class="[false ? 'bg-gray-200 cursor-not-allowed text-gray-500' : 'bg-blue-100 text-blue-500 hover:bg-blue-200 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-offset-2']" class="justify-center transition-all duration-300 rounded-md  text-xs font-semibold  shadow-sm  focus-visible:outline focus-visible:outline-2 focus-visible:outline-offset-2 inline-flex items-center px-2.5 py-1.5  select-none">
                  <ExternalLinkIcon class="-ml-0.5 h-5 w-5 mr-1" aria-hidden="true" />
                  <span class="whitespace-nowrap">Przejdź do kariery</span>
                </button>
            </div>
            </DialogPanel>
          </TransitionChild>
        </div>
      </div>
    </Dialog>
  </TransitionRoot>
</template>

<script>
import { ref } from 'vue'
import { Dialog, DialogOverlay, DialogPanel, DialogTitle, TransitionChild, TransitionRoot } from '@headlessui/vue'
import { TrendingUpIcon, ReplyIcon, ExternalLinkIcon } from '@heroicons/vue/outline'
import { ArrowCircleLeftIcon} from '@heroicons/vue/solid'
import { Fireworks } from 'fireworks-js'
import axios from "axios"
export default {
  components: {
    Dialog,
    DialogOverlay,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
    TrendingUpIcon,
    ArrowCircleLeftIcon,
    ExternalLinkIcon,
    ReplyIcon
  },
  props: [],
  methods: {
    async modalEvent(redirect) {
      if(redirect) {
        this.$router.push('/dashboard/career')
      }
      this.open = false
      setTimeout(() => {
        this.$store.state.showNotification = false
      }, 300);
      axios.post(`${this.$store.state.apiLink}/notifications/set/readed`,{}, {
        headers: {
          "apikey": this.$store.state.apiKey,
          "userkey": this.$store.state.userKey,
          "userid": this.$store.state.userId
        }
      }) 
    }
  },
  mounted() {
    setTimeout(() => {
      const container = document.querySelector('.background')
      const fireworks = new Fireworks(container,{
        autoresize: true,
        opacity: 0.5,
        acceleration: 1.05,
        friction: 0.97,
        gravity: 1.5,
        particles: 100,
        traceLength: 3,
        traceSpeed: 10,
        explosion: 6,
        intensity: 30,
        flickering: 10,
        lineStyle: 'round',
        hue: {
          min: 0,
          max: 360
        },
        delay: {
          min: 30,
          max: 60
        },
        rocketsPoint: {
          min: 50,
          max: 50
        },
        lineWidth: {
          explosion: {
            min: 3,
            max: 5
          },
          trace: {
            min: 1,
            max: 2
          }
        },
        brightness: {
          min: 50,
          max: 80
        },
        decay: {
          min: 0.015,
          max: 0.03
        },
        mouse: {
          click: false,
          move: false,
          max: 1
        }
      })
      fireworks.start()
    }, 300);
  },
  setup() {
    const open = ref(true)
    return {
      open,
    }
  },
}
</script>
